.googleIcons {
  transform: translateX(-2px);
  margin-right: 3px;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
.ovr-nav-links {
  border-bottom: 1px dashed #add1eb;
}
.ovr-nav-links:hover {
  color: #cae4f7;
  font-weight: 600;
}
.ovr-adminBackground {
  background-color: #f7fcff;
}
.ovr-adminHeading {
  color: #000000;
  text-shadow: 0px 0px 1px rgb(182, 182, 182);
  font-size: 35px;
}
.ovr-adminCaption {
  color: #000000;
}
.ovr-cardBody {
  box-shadow: 0px 0px 5px rgb(214, 214, 214);
  border-radius: 10px;
}
.card-animate {
  border-radius: 10px;
}
.ovr-admin-card-caption {
  color: #fff;
  font-weight: 500;
  font-size: 0.9rem;
  font-size: 0.9rem;
  text-shadow: 0px 0px 5px rgb(0, 0, 0);
}
.card-data-color {
  text-shadow: 0px 0px 5px rgb(0, 0, 0);
  color: #fff;
}

.loginBackgroundImage {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
.loginHeading {
  color: #000000;
  font-size: 40px;
  border-radius: 20px;
}
.loginCaption {
  color: #838383;
}
.loginSeparation {
  border-top: 1px dashed rgb(206, 206, 206);
  padding-top: 20px;
}
.loginBox {
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgb(151, 151, 151);
}
.ovr-dashboard-icons_box {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 0.3rem;
  width: fit-content;
  height: fit-content;
}
.ovr-dashboard-icons {
  width: 2.5rem;
  height: 2.5rem;
}
.topNotification {
  width: 50vw;
}
.Last-24-Hours-Usage {
  background-color: #8ec9bf;
}
.Current-Week-Usage {
  background-color: #999c83;
}
.Curren-Month-Usage {
  background-color: #8ebee6;
}
.Leaks {
  background-color: #fa8a8a;
}
.Open-Tickets {
  background-color: #837e45;
}
.Todays-Transactions {
  background-color: #7a7474;
}
.Users {
  background-color: #d4ae8e;
}
.Active-Devices {
  background-color: #dd9dc8;
}
.headerLogin {
  background-color: transparent;
}
